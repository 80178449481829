<template>
  <div>

  </div>
</template>

<script>
export default {
  name: 'ShepherdExample',
  mounted() {
    this.$nextTick(() => {
      const tour = this.$shepherd({
        useModalOverlay: true,
        defaultStepOptions: {
          classes: 'class-1 class-2',
          scrollTo: {behavior: 'smooth', block: 'center'}
        }
      });
      tour.addStep({
        attachTo: {
          element: '#welcomeBack',
          on: 'top'
        },

        buttons: [
          {
            action() {
              return this.cancel();
            },
            classes: 'shepherd-button-secondary',
            text: 'Tour beenden'
          },
          {
            action() {
              return this.next();
            },
            classes: 'weiterButton',
            text: 'Weiter'
          }
        ],
        title: '1/6 Willkommen auf deinem Dashboard!',
        text: 'In diesem Abschnitt, findest du neue Benachrichtigungen.'
      });
      tour.addStep({
        attachTo: {
          element: '#upcomingWeddings',
          on: 'top'
        },
        buttons: getDefaultButtons(),
        title: '2/6 Hochzeiten',
        text: 'In diesem Abschnitt werden alle anstehenden Hochzeiten angezeigt. <br><br> Unter dem Menüpunkt "Hochzeiten" kannst du neue Hochzeiten erstellen, löschen und einsehen.'
      });

      tour.addStep({
        attachTo: {
          element: '#openTasks',
          on: 'top'
        },
        buttons: getDefaultButtons(),
        title: '3/6 Hochzeiten',
        text: 'Hier findest du alle Aufgaben, die noch nicht erledigt wurden. <br><br> Neue Aufgaben können über den Menüpunkt "Todo-List" oder direkt aus einer bestehenden Hochzeit erstellt werden.'
      });
      tour.addStep({
        attachTo: {
          element: '#upcomingEvents',
          on: 'top'
        },
        buttons: getDefaultButtons(),
        title: '4/6 Hochzeiten',
        text: 'Anstehende Termine, Aufgabenfristen oder Hochzeiten werden in diesem Bereich dargestellt. <br><br>Eine Kalender-Ansicht und die Möglichkeit neue Termine zu erstellen findest du unter dem Menüpunkt "Termine".'
      });
      tour.addStep({
        attachTo: {
          element: '#toolbar',
          on: 'bottom'
        },
        buttons: getDefaultButtons(),
        title: '5/6 Hochzeiten',
        text:'Über die Icons in der Toolbar kannst du deine Einstellungen anpassen, deine Benachrichtigungen einsehen oder dich ausloggen.'
      });
      tour.addStep({
        attachTo: {
          element: '#navDrawer',
          on: 'top'
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            classes: 'shepherd-button-secondary',
            text: 'Zurück'
          },
          {
            action() {
              return this.cancel();
            },
            classes: 'weiterButton',
            text: 'Tour beenden'
          }
          ],
        title: '6/6 Hochzeiten',
        text:  'Über die Navigationsleiste kannst du in die verschiedenen Detailbereiche abspringen. <br><br> <b>Jetzt bist du dran!</b><br> Viel Spaß beim Entdecken der Funktionen! Wenn du Hilfe benötigst oder die Tour wiederholen möchtest, klicke einfach auf das Hilfe-Symbol oben rechts'
      });
      tour.start();
    });
  }
};

function getDefaultButtons() {
  return [
    {
      action() {
        return this.cancel();
      },
      classes: 'shepherd-button-secondary',
      text: 'Tour beenden'
    },
    {
      action() {
        return this.back();
      },
      classes: 'shepherd-button-secondary',
      text: 'Zurück'
    },
    {
      action() {
        return this.next();
      },
      classes: 'weiterButton',
      text: 'Weiter'
    }
  ]
}
</script>
<style>
.weiterButton {
  background-color: #6D7E6D !important;
}
</style>

